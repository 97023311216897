<template>
  <div class="card card-custom card-stretch gutter-b bg-dark">
    <!--begin::Body-->
    <div v-if="thePlant" class="d-flex flex-column">
      <div class="flex-grow-1">
        <!--begin::title-->
        <div class="text-white mx-8 mt-6 font-weight-bolder font-size-h4">
          {{ thePlant.NAME }}
        </div>
        <!--end::title-->
        <!--begin::image-->
        <div class="h-md-90">
          <img ref="siteImage" style="height: 200px; width: 100%" />
        </div>
        <!--end::image-->
      </div>
      <!--begin::desc-->
      <div class="card">
        <div class="card-body d-flex flex-column">
          <!--begin::address-->
          <p class="text-primary">{{ thePlant.ADDRESS }}</p>

          <!--end::address-->
          <!--begin::prop-->
          <div class="d-flex justify-content-between">
            <img ref="siteEquipmentDashboard" style="width: 100%" />
          </div>
          <!--end::prop-->
          <!--begin::equipments-->
          <h5 class="py-4">Installed Equipment</h5>
          <div class="d-flex flex-column mb-5">
            <div v-for="(equipment, index) in equipmentsList" :key="index">
              <div class="d-flex justify-content-between">
                <div class="text-dark-75 text-uppercase" cols="5">
                  {{ equipment.type }}
                </div>
                <div class="text-dark-75" cols="4">
                  {{ equipment.property }}
                  {{ equipment.unit }}
                </div>
              </div>
              <hr />
            </div>
          </div>
          <!--end::equipments-->
          <!--begin::button-->
          <b-button variant="primary" @click="changeRoute"
            >Performance &amp; Benefits</b-button
          >
          <!--end::button-->
        </div>
      </div>
      <!--end::desc-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { checkPropertyUnit } from "@/helpers/Regex";
import { Auth } from "aws-amplify";

export default {
  name: "PlantOverviewCard",
  props: ["thePlant"],
  data() {
    return {
      equipmentsList: [],
      user: {},
      session: {},
    };
  },
  async mounted() {
    this.user = await Auth.currentAuthenticatedUser();
    this.session = await Auth.currentSession();
    this.getImage(this.thePlant.SITE_ID);
    this.getEquipments(this.thePlant.SITE_ID);
  },
  methods: {
    getImage(siteId) {
      this.$refs.siteImage.src =
        "/api/get_site_image?jwtToken=" +
        this.user.signInUserSession.idToken.jwtToken +
        "&siteId=" +
        siteId;
      this.$refs.siteEquipmentDashboard.src =
        "/api/get_site_equipment_dashboard?jwtToken=" +
        this.user.signInUserSession.idToken.jwtToken +
        "&siteId=" +
        siteId;
    },
    changeRoute() {
      this.$router.push(`/performance/${this.thePlant.SITE_ID}`);
    },
    getEquipments(siteName) {
      ApiService.query("/api/discovery/get_equipment_discovery", {
        params: {
          jwtToken: this.user.signInUserSession.idToken.jwtToken,
          siteId: siteName,
        },
      }).then((res) => {
        let equipmentData = res.data;
        let propertiesArray = [];
        let tempProperty = {
          type: "",
          property: "",
          unit: "",
        };

        for (let equipment in equipmentData) {
          let propertiesList = equipmentData[equipment];

          for (let property in propertiesList) {
            if (checkPropertyUnit(property)) {
              tempProperty.unit = propertiesList[property];
              propertiesArray.push({ ...tempProperty });
            } else {
              tempProperty = {
                type: "",
                property: "",
                unit: "",
              };
              tempProperty.type = equipment;
              tempProperty.property = propertiesList[property];
            }
          }
        }
        this.equipmentsList = propertiesArray;
      });
    },
  },
};
</script>
